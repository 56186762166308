// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Anton&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #86b971;
  position: relative;
}

.loading-wrapper svg {
  position: absolute;
}

.loading-container {
  height: 200px;
  width: 200px;
  margin: 20% auto;
  position: relative;
  filter: url(#goo);
}

.loading-container .loading-text {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Anton", sans-serif;
  font-size: 200px;
  z-index: 999;
  color: white;
}

.loading-container .loading-drop {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: white;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(120%, -800%) scaleX(0.5);
  animation: loading-move 6s cubic-bezier(1, 0.04, 0.74, 0.2) infinite;
}

@keyframes loading-move {
  0% {
    transform: translate(120%, -800%) scaleX(0.2);
    height: 60px;
  }
  30% {
    transform: translate(120%, -200%);
    height: 30px;
  }
  70% {
    transform: translate(120%, 100%);
    height: 30px;
  }
  100% {
    transform: translate(120%, 800%) scaleX(0.2);
    height: 60px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loading.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,gCAAgC;EAChC,gCAAgC;EAChC,gBAAgB;EAChB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,6CAA6C;EAC7C,oEAAoE;AACtE;;AAEA;EACE;IACE,6CAA6C;IAC7C,YAAY;EACd;EACA;IACE,iCAAiC;IACjC,YAAY;EACd;EACA;IACE,gCAAgC;IAChC,YAAY;EACd;EACA;IACE,4CAA4C;IAC5C,YAAY;EACd;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Anton&display=swap\");\n\n.loading-wrapper {\n  width: 100%;\n  height: 100vh;\n  overflow: hidden;\n  background-color: #86b971;\n  position: relative;\n}\n\n.loading-wrapper svg {\n  position: absolute;\n}\n\n.loading-container {\n  height: 200px;\n  width: 200px;\n  margin: 20% auto;\n  position: relative;\n  filter: url(#goo);\n}\n\n.loading-container .loading-text {\n  position: absolute;\n  top: 0%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  font-family: \"Anton\", sans-serif;\n  font-size: 200px;\n  z-index: 999;\n  color: white;\n}\n\n.loading-container .loading-drop {\n  height: 30px;\n  width: 30px;\n  border-radius: 15px;\n  background-color: white;\n  position: absolute;\n  top: 5%;\n  left: 50%;\n  transform: translate(120%, -800%) scaleX(0.5);\n  animation: loading-move 6s cubic-bezier(1, 0.04, 0.74, 0.2) infinite;\n}\n\n@keyframes loading-move {\n  0% {\n    transform: translate(120%, -800%) scaleX(0.2);\n    height: 60px;\n  }\n  30% {\n    transform: translate(120%, -200%);\n    height: 30px;\n  }\n  70% {\n    transform: translate(120%, 100%);\n    height: 30px;\n  }\n  100% {\n    transform: translate(120%, 800%) scaleX(0.2);\n    height: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
