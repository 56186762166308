import React from "react";
import "./NutritionistDetails.css";

const NutritionistDetails = () => (
  <section className="profile">
    <div className="profile-bg"></div>
    <section className="container">
      <aside className="profile-image"></aside>
      <section className="profile-info">
        <h1 className="first-name">Dt. Anchal</h1>
        <h1 className="second-name">Bhardwaj</h1>
        <h2 className="sub-heading">ABOUT</h2>
        <p>
          I'm Dt. Anchal Bhardwaj, a dedicated and certified dietitian with a comprehensive background in nutrition and dietetics. I am the founder of NourishDhara, a venture I launched a year ago to
          provide expert nutritional guidance and support to individuals dealing with various health conditions.
        </p>
        <h3 className="sub-heading">Academic Background:</h3>
        <ul>
          <li>BSc in Food and Nutrition</li>
          <li>MSc in Dietetics and Food Service Management</li>
          <li>Certifications in Food Allergies, Pregnancy and Postpartum Diet Planning, and Diabetes Education</li>
        </ul>
        <h3 className="sub-heading">Professional Experience:</h3>
        <ul>
          <li>Dietitian at Sahayta Nutrition</li>
          <li>Dietitian at Akash Hospital</li>
          <li>Dietitian at Dr. Ram Manohar Lohia (RML) Hospital</li>
        </ul>
        <p>In these roles, I have developed and refined my skills in clinical nutrition, offering tailored dietary solutions and working closely with patients to improve their health outcomes.</p>
        <aside className="social-media-icons">
          <a href="https://www.instagram.com/nourishdhara/" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.threads.net/@nourishdhara" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-threads"></i>
          </a>
          <a href="https://www.facebook.com/profile.php?id=100094915893902" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-square-facebook"></i>
          </a>
          <a href="https://www.linkedin.com/in/dt-anchal-bhardwaj-8552a7137" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-linkedin"></i>
          </a>
        </aside>
      </section>
    </section>
  </section>
);

export default NutritionistDetails;
