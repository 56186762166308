import React from "react";
import "./Loading.css";

const Loading = () => (
  <div className="loading-wrapper">
    <svg>
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="name" />
          <feColorMatrix
            in="name"
            mode="matrix"
            values="1 0 0 0 0
                    0 1 0 0 0
                    0 0 1 0 0
                    0 0 0 15 -10"
            result="b"
          />
          <feBlend in="SourceGraphic" in2="b" />
        </filter>
      </defs>
    </svg>

    <div className="loading-container">
      <p className="loading-text">LOADING</p>
      <div className="loading-drop"></div>
    </div>
  </div>
);

export default Loading;
