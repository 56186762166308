import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-container">
        <h1>
          Nourish <span>Dhara</span>
        </h1>
        <div className="content">
          <p>Your trusted companion in achieving a healthier lifestyle. Discover personalized wellness solutions tailored just for you.</p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
