// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
  display: grid;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.hero .hero-container {
  background: #f5f5f5b5;
  border-radius: 15px;
  position: absolute;
  top: 25%;
  right: 3%;
  max-width: 30%;
  margin-top: auto;
  padding: 20px 40px;
}

.hero-container h1 {
  font-size: 3em;
  color: #757472;
}

.hero-container h1 span {
  color: #8cba7b;
}

.hero-container .content {
  font-size: 1.2em;
  line-height: 1.5em;
}
`, "",{"version":3,"sources":["webpack://./src/components/Hero.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,cAAc;EACd,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".hero {\n  display: grid;\n  width: 100%;\n  margin-right: auto;\n  margin-left: auto;\n  position: relative;\n  height: 100%;\n  overflow: hidden;\n}\n\n.hero .hero-container {\n  background: #f5f5f5b5;\n  border-radius: 15px;\n  position: absolute;\n  top: 25%;\n  right: 3%;\n  max-width: 30%;\n  margin-top: auto;\n  padding: 20px 40px;\n}\n\n.hero-container h1 {\n  font-size: 3em;\n  color: #757472;\n}\n\n.hero-container h1 span {\n  color: #8cba7b;\n}\n\n.hero-container .content {\n  font-size: 1.2em;\n  line-height: 1.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
