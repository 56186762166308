import React from "react";

const MainImage = () => (
  <section className="main-image">
    <h2>Nutrition in Focus</h2>
    <p>Explore the benefits of a balanced diet through images and case studies.</p>
    <img src="main_image.jpg" alt="Healthy Food" />
  </section>
);

export default MainImage;
