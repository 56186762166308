import React from "react";
import "./Recipes.css";
import recipes_cutlets from "../assets/recipes_cutlets.jpeg";
import recipes_dip from "../assets/recipes_dip.jpg";
import recipes_salad from "../assets/cucumberAvacado.jpg";
import recipes_chickpea from "../assets/recipe_chickpea.jpg";

const Recipes = () => (
  <section className="recipes">
    <ul className="cards">
      <li className="cards_item">
        <div className="card">
          <div className="card_image">
            <img src={recipes_chickpea} alt="mixed vegetable salad in a mason jar." />
          </div>
          <div className="card_content">
            <h2 className="card_title">Spinach and Chickpea Salad</h2>
            <div className="card_text">
              <p>
                <strong>Ingredients:</strong>
                <ul>
                  <li>⁠1 cup fresh spinach leaves</li>
                  <li>⁠1 cup cooked chickpeas</li>
                  <li>⁠1/4 cup diced cucumber</li>
                  <li>⁠1/4 cup diced tomatoes</li>
                  <li>⁠1 tablespoon lemon juice</li>
                  <li>⁠1 tablespoon olive oil</li>
                  <li>⁠Salt and pepper to taste</li>
                </ul>
              </p>
              <p>
                <strong>Instructions:</strong>
                <ul>
                  <li>In a bowl, combine spinach, chickpeas, cucumber, and tomatoes.</li>
                  <li>Drizzle with lemon juice and olive oil. Season with salt and pepper, then toss to mix well.</li>
                  <li>Season with salt and pepper, then toss to mix well.</li>
                  <li>⁠Serve immediately.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </li>

      <li className="cards_item">
        <div className="card">
          <div className="card_image">
            <img src={recipes_salad} alt="a Reuben sandwich on wax paper." />
          </div>
          <div className="card_content">
            <h2 className="card_title">Cucumber and Avocado Salad</h2>
            <div className="card_text">
              <p>
                <strong>Ingredients:</strong>
                <ul>
                  <li>⁠1 cucumber, diced</li>
                  <li>⁠⁠1 avocado, diced</li>
                  <li>⁠1 tablespoon lemon juice</li>
                  <li>⁠⁠1 tablespoon olive oil</li>
                  <li>⁠⁠Salt and pepper to taste</li>
                </ul>
              </p>
              <p>
                <strong>Instructions:</strong>
                <ul>
                  <li> ⁠In a bowl, combine cucumber and avocado.</li>
                  <li>Drizzle with lemon juice and olive oil.</li>
                  <li>⁠Season with salt and pepper, then toss to mix well.</li>
                  <li>⁠Serve immediately.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </li>

      <li className="cards_item">
        <div className="card">
          <div className="card_image">
            <img src={recipes_cutlets} alt="A side view of a plate of figs and berries." />
          </div>
          <div className="card_content">
            <h2 className="card_title">Air-Fried Vegetable Quinoa Cutlets</h2>
            <div className="card_text">
              <p>
                <strong>Ingredients:</strong>
                <ul>
                  <li>⁠1 cup cooked quinoa</li>
                  <li>⁠1 cup grated carrot</li>
                  <li>⁠1 cup grated cucumber (squeezed dry)</li>
                  <li>⁠1/2 cup finely chopped spinach</li>
                  <li>⁠1 small onion, finely chopped</li>
                  <li>⁠2 tablespoons chickpea flour (besan)</li>
                  <li>⁠1 teaspoon cumin powder</li>
                  <li>⁠Salt and pepper to taste. Olive oil spray</li>
                </ul>
              </p>
              <p>
                <strong>Instructions:</strong>
                <ul>
                  <li>Mix all ingredients in a bowl.</li>
                  <li>Form mixture into patties.</li>
                  <li>⁠Preheat air fryer to 180°C (350°F).</li>
                  <li>⁠Place patties in the air fryer, spray with olive oil, and cook for 10-12 minutes, flipping halfway. ⁠Serve hot.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </li>

      <li className="cards_item">
        <div className="card">
          <div className="card_image">
            <img src={recipes_dip} alt="A side view of a plate of figs and berries." />
          </div>
          <div className="card_content">
            <h2 className="card_title">Paneer and Spinach Dip</h2>
            <div className="card_text">
              <p>
                <strong>Ingredients:</strong>
                <ul>
                  <li>⁠200 grams paneer, crumbled</li>
                  <li>⁠1 cup spinach, blanched and chopped</li>
                  <li>⁠1/2 cup Greek yogurt</li>
                  <li>⁠1 clove garlic, minced</li>
                  <li>⁠1 tablespoon lemon juice</li>
                  <li>⁠1 tablespoon olive oil</li>
                  <li>⁠Salt and pepper to taste</li>
                </ul>
              </p>
              <p>
                <strong>Instructions:</strong>
                <ul>
                  <li>⁠Mix crumbled paneer, chopped spinach, Greek yogurt, minced garlic, olive oil, lemon juice, salt, and pepper in a bowl.</li>
                  <li>⁠Chill in the refrigerator for 30 minutes.</li>
                  <li> ⁠Serve with vegetable sticks or crackers.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>
);

export default Recipes;
