import React from "react";
import cardBg from "../assets/card-bg.jpg";
import diabetesBg from "../assets/diabetes.jpg";
import pcosBg from "../assets/pcosBg.jpeg";

const Details = () => (
  <section className="details">
    <h2 className="heading">Specialized Programs</h2>
    <div className="card-container">
      <div className="card-hover">
        <div className="card-hover__content">
          <h3 class="card-hover__title">Weight Loss</h3>
          <p class="card-hover__text">
            Personalized plans to help you lose weight safely and sustainably. A healthy lifestyle includes getting exercise every day along with eating a healthy diet. A healthy lifestyle may lower
            disease risks, such as obesity, heart disease, type 2 diabetes, hypercholesterolemia, hypertension, and cancer.
          </p>
        </div>
        <img src={cardBg} alt="Weight Loss" />
      </div>
      <div className="card-hover">
        <div className="card-hover__content">
          <h3 class="card-hover__title">PCOS Management</h3>
          <p class="card-hover__text">
            Expert guidance on managing PCOS through nutrition and lifestyle changes. My approach includes tailored diet plans that address the specific needs of women with PCOS, helping to manage
            symptoms and improve overall health.
          </p>
        </div>
        <img src={pcosBg} alt="PCOS Management" />
      </div>
      <div className="card-hover">
        <div className="card-hover__content">
          <h3 class="card-hover__title">Diabetes Management</h3>
          <p class="card-hover__text">
            Effective strategies to manage diabetes through diet. I offer comprehensive nutrition plans that help control blood sugar levels, improve insulin sensitivity, and promote overall
            well-being for individuals with diabetes.
          </p>
        </div>
        <img src={diabetesBg} alt="Diabetes" />
      </div>
    </div>
  </section>
);

export default Details;
