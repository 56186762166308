import React, { useEffect, useRef, useState, useMemo } from "react";
import "./App.css";
import Loading from "./components/Loading";
import Hero from "./components/Hero";
import Banner from "./components/Banner";
import MainImage from "./components/MainImage";
import NutritionistDetails from "./components/NutritionistDetails";
import Details from "./components/Details";
import Footer from "./components/Footer";
import ReactPageScroller from "react-page-scroller";
import Recipes from "./components/Recipes";

const App = () => {
  const [currentPage, setCurrentPage] = useState(null);

  const handlePageChange = (number) => {
    setCurrentPage(number);
  };

  const handleBeforePageChange = (number) => {
    console.log(number);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 6000); // 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App">
      <ReactPageScroller pageOnChange={handlePageChange} onBeforePageScroll={handleBeforePageChange} customPageNumber={currentPage}>
        <div className="section hero-wrapper">
          <div class="waves"></div>
          <Hero />
        </div>
        <div className="section details-wrapper">
          <Details />
        </div>
        <div className="section recipes-section">
          <Recipes />
        </div>
        {/* <div className="section">
          <MainImage />
        </div> */}
        <div className="section nutritionist-section">
          <NutritionistDetails />
        </div>
        {/* <div className="section details-wrapper">
          <Banner />

          <Footer />
        </div> */}
      </ReactPageScroller>
    </div>
  );
};

export default App;
